import { Outlet, Navigate } from "react-router-dom";
import MainLayout from "../components/main";
import { useDispatch, useSelector } from "react-redux";
import { authActions } from "../store/app-auth";
import axios from "axios";
import { useEffect } from "react";
import jwt_decode from "jwt-decode";

const PrivateRoutes = () => {
  const dispatch = useDispatch();
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const authToken = useSelector((state) => state.auth.authToken);
  const adminId = useSelector((state) => state.auth.adminId);

  useEffect(() => {
    if (isLoggedIn) {
      tokenHandler();
    }
  }, [isLoggedIn]);

  const tokenHandler = () => {
    /* เช็คทุก 3 วินาที token expired จะสั่ง logout */
    try {
      if (authToken !== undefined) {
        let tokenInfo = jwt_decode(authToken);
        let tokenExpiration = tokenInfo.exp * 1000;
        let checkTokenExpire = setInterval(() => {
          if (localStorage.getItem("authToken") && tokenExpiration < new Date().getTime()) {
            clearInterval(checkTokenExpire);
            dispatch(authActions.logout());
          }
        }, 1000);

        if (!adminId) {
          // userInfoHandler();
        } else {
          setTimeout(() => {}, 1500);
        }
      } else {
        console.log("invalid credentials");
        dispatch(authActions.logout());
      }
    } catch (error) {
      console.log("invalid authorization");
      dispatch(authActions.logout());
    }
  }
  
  return <MainLayout>{isLoggedIn ? <Outlet /> : <Navigate to="/login" />}</MainLayout>;
};
export default PrivateRoutes;
