import { createSlice } from "@reduxjs/toolkit";

const initState = {
  showSidebar: true,
  isNavsideCollapse: false,
  langId: localStorage.langId ? localStorage.langId : "1",
};

const appSlice = createSlice({
  name: "app",
  initialState: initState,
  reducers: {
    setSubmenu(state, action) {
      state.showSidebar !== false ? (state.showSidebar = false) : (state.showSidebar = true);
    },
    toggleNavsideCollapse(state, action) {
      if (action.payload !== undefined) {
        state.isNavsideCollapse = action.payload;
      } else {
        state.isNavsideCollapse = !state.isNavsideCollapse;
      }
    },
    setLanguage(state, action) {
      localStorage.setItem('langId',action.payload)
      state.langId = localStorage.langId;
    },
  },
});

export const appActions = appSlice.actions;
export default appSlice;
