
import './main.scss'
import FooterComponent from './layout/footer/footer';
import SidebarComponent from "./layout/sidebar/sidebar";
import NavBarComponents  from './layout/Navbar/navbar';
import { useDispatch, useSelector } from 'react-redux';
import { appActions } from "../store/app-slice";

const MainLayout = (props) =>{
      const dispatch = useDispatch();
      const isNavsideCollapse = useSelector((state) => state.app.isNavsideCollapse);

      const collapseHandler = (status = undefined) => {
        dispatch(appActions.toggleNavsideCollapse(status));
      };

    return (
      <div className={`App ${isNavsideCollapse ? "collapsed" : ""}`}>
        {/* <NavBarComponents collapseHandler={collapseHandler} isCollapsed={isNavsideCollapse} /> */}
        <SidebarComponent collapseHandler={collapseHandler} />
        <div className="main-body">
          <main>{props.children}</main>
        </div>
        s
        <FooterComponent />
      </div>
    );
}
export default MainLayout;