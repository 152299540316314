import { Button, Modal, Box, TextField, Radio, FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import { useEffect, useState } from "react";
import SwalUI from "../../../components/ui/swal-ui";
import { useSelector } from "react-redux";
import { updateVersion } from "../../../services/version.service";
const dataDefault = {
  id: 0,
  versionNumber: "",
  releaseNotes: "",
  releaseDate: "",
  status: "",
};
const EditModel = (props) => {
  const { openEditModal, setOpenEditModal, adminEditData } = props;
  const [editData, setEditData] = useState(dataDefault);

  useEffect(() => {
    console.log(adminEditData);
    if (adminEditData !== null) {
      setEditData(adminEditData);
    }
  }, [adminEditData]);

  const onSaveCate = () => {
    // const formData = new FormData();
    const getData = {
      id: editData.id,
      version_number: editData.versionNumber,
      release_notes: editData.releaseNote,
      release_date: editData.releaseDate,
      status: editData.status,
    };

    updateVersion(getData, editData.id).then((res) => {
      if (res.status) {
        props.setRefreshData((prev) => prev + 1);
        props.setOpenEditModal(false);
        SwalUI({ status: res.status, description: res.data.description });
      } else {
        console.log("fail:" + res.status);
        SwalUI({ status: false, description: res.description });
      }
    });
  };

  return (
    <Modal
      className="custom-modal"
      open={openEditModal}
      onClose={() => props.setOpenEditModal(false)}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <Box className="body-modal">
        <div className="modal-top">
          <h1>แก้ไข</h1>
        </div>
        <TextField
          className="custom-input"
          id="versionNumber"
          size="small"
          variant="outlined"
          label="Version Number"
          value={editData.versionNumber ? editData.versionNumber : ""}
          onChange={(e) => {
            setEditData((prevState) => {
              return { ...prevState, versionNumber: e.target.value };
            });
          }}
        />
        <TextField
          className="custom-input"
          id="releaseNote"
          size="small"
          variant="outlined"
          label="Release Note"
          value={editData.releaseNote ? editData.releaseNote : ""}
          onChange={(e) => {
            setEditData((prevState) => {
              return { ...prevState, releaseNote: e.target.value };
            });
          }}
        />
        <TextField
          className="custom-input"
          id="releaseDate"
          type="date"
          size="small"
          variant="outlined"
          label="Release Date"
          value={editData.releaseDate ? editData.releaseDate : ""}
          onChange={(e) => {
            setEditData((prevState) => {
              return { ...prevState, releaseDate: e.target.value };
            });
          }}
        />
        <FormControl fullWidth size="small">
          <InputLabel id="status">Status</InputLabel>
          <Select
            labelId="status"
            id="status"
            value={editData.status}
            label="Status"
            onChange={(e) => {
              setEditData((prevState) => {
                return { ...prevState, status: e.target.value };
              });
            }}
          >
            <MenuItem value="Active">Active</MenuItem>
            <MenuItem value="Inactive">Inactive</MenuItem>
          </Select>
        </FormControl>

        <div className="modal-footer">
          <Button className="btn-cancel" onClick={() => props.setOpenEditModal(false)}>
            CANCEL
          </Button>
          <Button className="btn-save" onClick={onSaveCate}>
            SAVE
          </Button>
        </div>
      </Box>
    </Modal>
  );
};
export default EditModel;
