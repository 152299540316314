import axios from "axios";

export const getVersion = () => {
  return axios.get(`/version`).then(
    (res) => {
      return { status: true, data: res.data.data };
    },
    (error) => {
      return {
        status: false,
        description: !error.response.data
          ? "Something went wrong."
          : error.response.data.description,
      };
    }
  );
};

export const addVersion = (data) => {
  return axios.post(`/version/add`, data).then(
    (res) => {
      return { status: true, data: res.data.data };
    },
    (error) => {
      return {
        status: false,
        description: error.response.data.description,
      };
    }
  );
};

export const updateVersion = (data) => {
  return axios.put(`/version/update`, data).then(
    (res) => {
      return { status: true, data: res.data.data };
    },
    (error) => {
      return {
        status: false,
        description: error.response.data.description,
      };
    }
  );
};

export const deleteVersion = (id) => {
  return axios.post(`/version/delete`, { id: id }).then(
    (res) => {
      return { status: true, data: res.data.data };
    },
    (error) => {
      return {
        status: false,
        description: error.response.data.description,
      };
    }
  );
};
