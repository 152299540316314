import { useEffect, useState } from "react";
import { getMember } from "../../services/member.service";
import "./member.scss";
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAdd, faBook, faEdit, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import SwalUI from "../../components/ui/swal-ui";
import { useSelector } from "react-redux";
const MemberPage = () => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [member, setMember] = useState([]);
  const [cateModalAdd, setCateModalAdd] = useState(false);
  const [refreshData, setRefreshData] = useState(0);
  const [cateEditData, setCateEditData] = useState(null);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [posts, setPosts] = useState([]);
  const [openManagePost, setOpenManagePost] = useState(false);
  const lang = useSelector((state) => state.app.langId);

  // useEffect(() => {
  //   getMember().then((res) => {
  //     let arrData = [];
  //     if (res.status) {
  //       for (const obj of res.data) {
  //         arrData[`${obj.id}`] = {
  //           id: obj.id,
  //           email: obj.email,
  //           name_lastname: obj.name_lastname,
  //           buy_status: obj.buy_status,
  //           expirde: obj.expirde,
  //           purchase_time: obj.purchase_time,
  //           order_id: obj.order_id,
  //         };
  //       }
  //     }
  //     setMember(arrData);
  //     console.log("then");
  //   });
  // }, [refreshData]);
   useEffect(() => {
     getMember().then((res) => {
       if (res.status) {
         // Use array directly instead of creating an object
         const arrData = res.data.map((obj) => ({
           id: obj.id,
           email: obj.email,
           name_lastname: obj.name_lastname,
           buy_status: obj.buy_status,
           expirde: obj.expirde,
           purchase_time: obj.purchase_time,
           order_id: obj.order_id,
         }));
         
         setMember(arrData);
         
       }
     });
   }, [refreshData]);

  const editCate = (item) => {
    setOpenEditModal(true);
    setCateEditData(item);
  };

  // Handle change in page
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // Handle change in rows per page
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Slice the data to display only the rows for the current page
  const paginatedMembers = member.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  return (
    <div className="category-page">
      <div className="title">
        <p>รายชื่อสมาชิก</p>
      </div>
      <div className="col-tabtop">
        <Button variant="outlined" onClick={() => setCateModalAdd(true)}>
          <FontAwesomeIcon icon={faAdd} />
          เพิ่ม
        </Button>
      </div>
      {/* <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <TableCell>name</TableCell>
              <TableCell>email</TableCell>
              <TableCell>status</TableCell>
              <TableCell>expirde</TableCell>
              <TableCell>purchase_time</TableCell>
              <TableCell>last order id</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {member.map((el) => (
              <TableRow key={el.id}>
                <TableCell align="left">{el.name_lastname}</TableCell>
                <TableCell align="left">{el.email}</TableCell>
                <TableCell align="left">{el.buy_status}</TableCell>
                <TableCell align="left">{el.expirde}</TableCell>
                <TableCell align="left">{el.purchase_time}</TableCell>
                <TableCell align="left">{el.order_id}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer> */}
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Expired</TableCell>
              <TableCell>Purchase Time</TableCell>
              <TableCell>Last Order ID</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedMembers.map((el) => (
              <TableRow key={el.id}>
                <TableCell align="left">{el.name_lastname}</TableCell>
                <TableCell align="left">{el.email}</TableCell>
                <TableCell align="left">{el.buy_status}</TableCell>
                <TableCell align="left">{el.expirde}</TableCell>
                <TableCell align="left">{el.purchase_time}</TableCell>
                <TableCell align="left">{el.order_id}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>

        {/* Pagination Controls */}
        <TablePagination
          component="div"
          count={member.length}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
    </div>
  );
};
export default MemberPage;
