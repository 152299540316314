import axios from "axios";

export const getMember = () => {
  return axios.get(`/all-member`).then(
    (res) => {
      return { status: true, data: res.data.data };
    },
    (error) => {
      return {
        status: false,
        description: !error.response.data
          ? "Something went wrong."
          : error.response.data.description,
      };
    }
  );
};
