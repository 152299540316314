import { useEffect, useState } from "react";
import "./version.scss";
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAdd, faTrash } from "@fortawesome/free-solid-svg-icons";
import AddModal from "./version-modal/add-modal";
import EditModel from "./version-modal/edit-modal";
import SwalUI from "../../components/ui/swal-ui";
import Swal from "sweetalert2";
import { deleteVersion, getVersion } from "../../services/version.service";

const VersionPage = () => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [refreshData, setRefreshData] = useState(0);
  const [admin, setAdmin] = useState([]);
  const [modalAdd, setModalAdd] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [adminEditData, setAdminEditData] = useState(null);
  const versionApp = localStorage.getItem("versionApp");

  useEffect(() => {
    getVersion().then((res) => {
      let arrData = [];
      if (res.status) {
        for (const obj of res.data) {
          arrData[`${obj.id}`] = {
            id: obj.id,
            versionNumber: obj.version_number,
            releaseNote: obj.release_notes,
            releaseDate: obj.release_date,
            status: obj.status,
          };
        }
      }
      if (versionApp !== arrData[arrData.length - 1].versionNumber) {
        localStorage.setItem("versionApp", arrData[arrData.length - 1].versionNumber);
      }

      setAdmin(arrData.reverse());
      console.log("then");
    });
  }, [refreshData]);

  const editAdmin = (item) => {
    setOpenEditModal(true);
    setAdminEditData(item);
  };

  // Handle change in page
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // Handle change in rows per page
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const paginatedAdmin = admin.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  const remove = (id) => {
    Swal.fire({
      title: "Do you want to save the changes?",
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: "ยืนยันการลบบัญชี",
      denyButtonText: `ยกเลิก`,
      confirmButtonColor: "#0048b3",
    }).then((result) => {
      console.log(result);
      if (result.isConfirmed) {
        deleteVersion(id).then((res) => {
          if (res.status) {
            setRefreshData((prev) => prev + 1);
            SwalUI({ status: res.status, description: "ลบสำเร็จ" });
          } else {
            SwalUI({ status: res.status, description: res.description });
          }
        });
      } else if (result.isDenied) {
        // Swal.fire("Changes are not saved", "", "info");
      }
    });
  };

  return (
    <section id="version-page">
      <div className="title">
        <p>เวอร์ชั่น</p>
      </div>
      <div className="col-tabtop">
        <Button variant="outlined" onClick={() => setModalAdd(true)}>
          <FontAwesomeIcon icon={faAdd} />
          เพิ่ม
        </Button>
      </div>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <TableCell>#</TableCell>
              <TableCell>Version Number</TableCell>
              <TableCell>Release Note</TableCell>
              <TableCell>Release Date</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Edit</TableCell>
              <TableCell>Delete</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedAdmin.map((el, index) => (
              <TableRow key={el.id}>
                <TableCell align="left">{el.id}</TableCell>
                <TableCell align="left">{el.versionNumber}</TableCell>
                <TableCell align="left">{el.releaseNote}</TableCell>
                <TableCell align="left">{el.releaseDate}</TableCell>
                <TableCell align="left">{el.status}</TableCell>
                <TableCell align="left">
                  <Button onClick={() => editAdmin(el)}> Edit</Button>
                </TableCell>
                <TableCell align="left">
                  <Button onClick={() => remove(el)}>
                    {" "}
                    <FontAwesomeIcon icon={faTrash} color="red" />
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>

        {/* Pagination Controls */}
        <TablePagination
          component="div"
          count={admin.length}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
      <AddModal
        modalAdd={modalAdd}
        setModalAdd={setModalAdd}
        setRefreshData={() => setRefreshData(refreshData + 1)}
      />
      <EditModel
        openEditModal={openEditModal}
        setOpenEditModal={setOpenEditModal}
        adminEditData={adminEditData}
        setRefreshData={() => setRefreshData(refreshData + 1)}
      />
    </section>
  );
};

export default VersionPage;
