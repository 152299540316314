import { Button, Modal, Box, TextField, Select, FormControl, InputLabel, MenuItem, InputAdornment } from "@mui/material";
import { useState } from "react";
import { addVersion } from "../../../services/version.service";
import SwalUI from "../../../components/ui/swal-ui";

const dataDefault = {
  versionNumber: "",
  releaseNote:"",
  releaseDate: "",
  status:""
};
const AddModal = (props) => {
  const { modalAdd, setModalAdd } = props;
  const [addData, setAddData] = useState(dataDefault);


  const onSaveCate = () => {
    const getData = {"version_number":addData.versionNumber ,"release_notes":addData.releaseNote,"release_date":addData.releaseDate,"status":addData.status}
    console.log(getData)
    addVersion(getData).then((res) => {
      if (res.status) {
        console.log(res.data.description);
        props.setRefreshData((prev) => prev + 1);
        props.setModalAdd(false);
        setAddData(dataDefault);
        SwalUI({ status: res.status, description: res.data.description });
      } else {
        SwalUI({ status: res.status, description: res.description });
      }
    });
  };
  return (
    <Modal
      className="custom-modal"
      open={modalAdd}
      onClose={() => props.setModalAdd(false)}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <Box className="body-modal">
        <div className="modal-top">
          <h1>เพิ่ม</h1>
        </div>
        <TextField
          className="custom-input"
          id="versionNumber"
          size="small"
          variant="outlined"
          label="Version Number"
          type="text"
          value={addData.versionNumber}
          onChange={(e) => {
            setAddData((prevState) => {
              return { ...prevState, versionNumber: e.target.value };
            });
          }}
        />
        <TextField
          className="custom-input"
          id="releaseNote"
          size="small"
          variant="outlined"
          label="Release Note"
          value={addData.releaseNote}
          onChange={(e) => {
            setAddData((prevState) => {
              return { ...prevState, releaseNote: e.target.value };
            });
          }}
        />
        <TextField
          className="custom-input"
          id="releaseDate"
          type="date"
          size="small"
          variant="outlined"
          // label="Release Date"
          slotProps={{
            input: {
              startAdornment: <InputAdornment position="start">Release Date: </InputAdornment>,
            },
          }}
          value={addData.releaseDate}
          onChange={(e) => {
            setAddData((prevState) => {
              return { ...prevState, releaseDate: e.target.value };
            });
          }}
        />

        <FormControl fullWidth size="small">
          <InputLabel id="status">Status</InputLabel>
          <Select
            labelId="status"
            id="status"
            value={addData.status}
            label="Status"
            onChange={(e) => {
              setAddData((prevState) => {
                return { ...prevState, status: e.target.value };
              });
            }}
          >
            <MenuItem value="Active">Active</MenuItem>
            <MenuItem value="Inactive">Inactive</MenuItem>
          </Select>
        </FormControl>

        <div className="modal-footer">
          <Button className="btn-cancel" onClick={() => props.setModalAdd(false)}>
            CANCEL
          </Button>
          <Button className="btn-save" onClick={onSaveCate}>
            SAVE
          </Button>
        </div>
      </Box>
    </Modal>
  );
};
export default AddModal;
