import {
  Button,
  Modal,
  Box,
  TextField,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { ManageInch } from "../../../services/inch.service";
import SwalUI from "../../../components/ui/swal-ui";
import { useSelector } from "react-redux";

const dataDefault = [
  {
    id: 0,
    title: "",
    description: "",
    position: "",
    language_id: 0,
  },
];
const ManagePost = (props) => {
  const { openManagePost, setOpenManagePost, posts } = props;
  const [allData, setAllData] = useState(dataDefault);
  const lang = useSelector((state) => state.app.langId);

  useEffect(() => {
    if (posts) {
      setAllData(posts);
    }
  }, [posts]);

  const onSaveCate = () => {
    const dataJson = allData;
    console.log(dataJson);

    ManageInch(dataJson).then((res) => {
      console.log(res);
      if (res.status) {
        console.log(res.data.description);
        props.setRefreshData((prev) => prev + 1);
        props.setOpenManagePost(false);
        SwalUI({ status: res.status, description: res.data.description });
      } else {
        SwalUI({ status: res.status, description: res.description });
      }
    });
  };

  const editDescription = (updateId, field, beforePosition) => (e) => {
    const newData = allData.map((el, i) => {
      if (el.position === e.target.value) {
        return { ...el, position: beforePosition };
      }
      if (updateId === el.id) {
        if (field === "title") {
          return { ...el, title: e.target.value };
        }
        if (field === "description") {
          return { ...el, description: e.target.value };
        }
        if (field === "position") {
          return { ...el, position: e.target.value };
        }
        return true;
      } else {
        return el;
      }
    });
    setAllData(newData);
  };

  return (
    <Modal
      className="custom-modal"
      open={openManagePost}
      onClose={() => props.setOpenManagePost(false)}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <Box className="body-modal">
        <div className="modal-top">
          <h1>จัดการเนื้อหา</h1>
        </div>
        <div className="modal-content">
          {allData.map((el, index) => (
            <div className="manage-posts" key={index}>
              <h1>เนื้อหาที่ {index + 1}</h1>
              <div className="content">
                <TextField
                  style={{ width: "20%" }}
                  className="custom-input"
                  id="cate-title"
                  size="small"
                  variant="outlined"
                  label="Title"
                  value={el.title}
                  onChange={editDescription(el.id, "title")}
                />
                <TextField
                  style={{ width: "70%" }}
                  className="custom-input"
                  id="cate-description"
                  size="small"
                  variant="outlined"
                  label="Description"
                  multiline
                  rows={1}
                  value={el.description}
                  onChange={editDescription(el.id, "description")}
                />
                <FormControl style={{ width: "10%" }}>
                  <InputLabel id="demo-simple-select-label">Position</InputLabel>
                  <Select
                    className="custom-input"
                    id="cate-position"
                    size="small"
                    variant="outlined"
                    label="Position"
                    value={el.position ? el.position : ""}
                    onChange={editDescription(el.id, "position", el.position)}
                  >
                    <MenuItem value={1}>1</MenuItem>
                    <MenuItem value={2}>2</MenuItem>
                    <MenuItem value={3}>3</MenuItem>
                    <MenuItem value={4}>4</MenuItem>
                  </Select>
                </FormControl>
              </div>
            </div>
          ))}
        </div>

        <div className="modal-footer">
          <Button className="btn-cancel" onClick={() => props.setOpenManagePost(false)}>
            CANCEL
          </Button>
          <Button className="btn-save" onClick={onSaveCate}>
            SAVE
          </Button>
        </div>
      </Box>
    </Modal>
  );
};
export default ManagePost;
