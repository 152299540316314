import { Button, Modal, Box, TextField, Radio } from "@mui/material";
import { useEffect, useState } from "react";
import SwalUI from "../../../components/ui/swal-ui";
import { editCmCate } from "../../../services/centimeter.service";
import { useSelector } from "react-redux";
const dataDefault = {
  cate_name: "",
  cate_description: "",
  position: "",
  language_id: 0, 
  luck: 0,
};
const EditModel = (props) => {
  const { openEditModal, setOpenEditModal, cateEditData } = props;
  const [editData, setEditData] = useState(dataDefault);
  const lang = useSelector((state) => state.app.langId);

  useEffect(() => {
    if (cateEditData !== null) {
      console.log("edit");
      setEditData(cateEditData);
    }
  }, [cateEditData]);

  const onSaveCate = () => {
    const formData = new FormData();
    formData.append("id", editData.id);
    formData.append("cate_name", editData.cate_name);
    formData.append("cate_description", editData.cate_description);
    formData.append("position", editData.position);
    formData.append("language_id", lang);
    formData.append("luck", editData.luck);
    editCmCate(formData).then((res) => {
      if (res.status) {
        props.setRefreshData((prev) => prev + 1);
        props.setOpenEditModal(false);
        SwalUI({ status: res.status, description: res.data.description });
      } else {
        console.log("fail:" + res.status);
        SwalUI({ status: false, description: res.description });
      }
    });
  };

  return (
    <Modal
      className="custom-modal"
      open={openEditModal}
      onClose={() => props.setOpenEditModal(false)}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <Box className="body-modal">
        <div className="modal-top">
          <h1>แก้ไข</h1>
        </div>
        <TextField
          className="custom-input"
          id="cate-name"
          size="small"
          variant="outlined"
          label="Title"
          value={editData.cate_name ? editData.cate_name : ""}
          onChange={(e) => {
            setEditData((prevState) => {
              return { ...prevState, cate_name: e.target.value };
            });
          }}
        />
        <TextField
          className="custom-input"
          id="cate-description"
          size="small"
          variant="outlined"
          label="Description"
          value={editData.cate_description ? editData.cate_description : ""}
          multiline
          rows={3}
          onChange={(e) => {
            setEditData((prevState) => {
              return { ...prevState, cate_description: e.target.value };
            });
          }}
        />
        <TextField
          className="custom-input"
          id="cate-position"
          size="small"
          variant="outlined"
          label="Position"
          value={editData.position ? editData.position : ""}
          onChange={(e) => {
            setEditData((prevState) => {
              return { ...prevState, position: e.target.value };
            });
          }}
        />
        <div className="radio-luck">
          <Radio
            checked={editData.luck === 0}
            onChange={(e) => {
              setEditData((prevState) => {
                return { ...prevState, luck: parseInt(e.target.value) };
              });
            }}
            value="0"
            name="radio-buttons"
            style={{ color: "red" }}
          />
          <p style={{ color: "red" }}>โชคดี</p>
          <Radio
            checked={editData.luck === 1}
            onChange={(e) => {
              setEditData((prevState) => {
                return { ...prevState, luck: parseInt(e.target.value) };
              });
            }}
            value="1"
            name="radio-buttons"
            style={{ color: "black" }}
          />
          <p>โชคร้าย</p>
        </div>

        <div className="modal-footer">
          <Button className="btn-cancel" onClick={() => props.setOpenEditModal(false)}>
            CANCEL
          </Button>
          <Button className="btn-save" onClick={onSaveCate}>
            SAVE
          </Button>
        </div>
      </Box>
    </Modal>
  );
};
export default EditModel;
