import axios from "axios";

export const getAppInfo = (langId) => {
  return axios.get(`/info/${langId}`).then(
    (res) => {
      return { status: true, data: res.data.data };
    },
    (error) => {
      return {
        status: false,
        description: !error.response.data
          ? "Something went wrong."
          : error.response.data.description,
      };
    }
  );
};

export const updateAboutApp = (formBody) => {
  return axios.post(`/update/info`, formBody).then(
    (res) => {
      return { status: true, data: res.data };
    },
    (error) => {
      console.log("error");
      return {
        status: false,
        description: !error.response.data
          ? "Something went wrong."
          : error.response.data.description,
      };
    }
  );
};

export const uploadBackgourndImage = (formBody) => {    
  return axios.post(`/upload/bg`, formBody).then(
    (res) => {
      return { status: true, data: res.data };
    },
    (error) => {
      console.log("error");
      return {
        status: false,
        description: !error.response.data
          ? "Something went wrong."
          : error.response.data.description,
      };
    }
  );
};

export const uploadRulerImage = (formBody) => {
  return axios.post(`/upload/ruler`, formBody).then(
    (res) => {
      return { status: true, data: res.data };
    },
    (error) => {
      console.log("error");
      return {
        status: false,
        description: !error.response.data
          ? "Something went wrong."
          : error.response.data.description,
      };
    }
  );
};