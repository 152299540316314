import { NavLink } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { appActions } from "../../../store/app-slice";
import { authActions } from "../../../store/app-auth";

import "./sidebar.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,
  faClipboard,
  faIdCardClip,
  faMobileAlt,
  faMobileScreen,
  faSignOut,
} from "@fortawesome/free-solid-svg-icons";
import { Button, ToggleButton, ToggleButtonGroup } from "@mui/material";
const SideBar = () => {
  const dispatch = useDispatch();
  const showSidebar = useSelector((state) => state.app.showSidebar);
  const sidebar_menu = useRef();
  const menu_group = useRef();
  const lang= useSelector((state) => state.app.langId);
  const [toggleLang, setToggleLang] = useState("horizontal");
  useEffect(() => {
    if (menu_group.current) {
      if (showSidebar !== false) {
        menu_group.current.classList.add("sub-active");
      } else {
        menu_group.current.classList.remove("sub-active");
      }
    }
  });
  const subMenu = (event) => {
    dispatch(appActions.setSubmenu());
    if (showSidebar !== false) {
      menu_group.current.classList.add("sub-active");
    } else {
      menu_group.current.classList.remove("sub-active");
    }
  };
  const hiddenSidebar = () => {
    sidebar_menu.current.classList.toggle("hidden");
    document.querySelector(".main-body").classList.toggle("hidden-menu");
    setToggleLang(toggleLang === "vertical" ? "horizontal" : "vertical");
  };

  const OnSignOutHandler = () => {
    dispatch(authActions.logout());
  };

  const changeLangHandler = (event, newAlignment) => {
    dispatch(appActions.setLanguage(event.target.value));
    window.location.reload();
    // setLang(newAlignment);
  };

  return (
    <aside className="sidebar-menu" ref={sidebar_menu}>
      <div className="col-logo">
        <img src={process.env.PUBLIC_URL + "/images/logocom.png"} alt="" />
        <h1>Luban</h1>
      </div>
      <div className="menu-lang">
        <div className="lang">
          <ToggleButtonGroup
            color="primary"
            value={lang}
            exclusive
            onChange={changeLangHandler}
            aria-label="Platform"
            orientation={toggleLang}
          >
            {/* <ToggleButton value={"1"}>TH</ToggleButton> */}
            {/* <ToggleButton value={"2"}>EN</ToggleButton>
            <ToggleButton value={"3"}>CN</ToggleButton> */}
          </ToggleButtonGroup>
        </div>
      </div>
      <div className="title-menu">
        <p>Menu</p>
        <div className="hidden-side">
          <FontAwesomeIcon icon={faBars} onClick={hiddenSidebar} />
        </div>
      </div>
      <ul className="menu-list">
        <NavLink to="/inch">
          <FontAwesomeIcon icon={faClipboard} />
          <p>Inch</p>
        </NavLink>
        <NavLink to="/centimeter">
          <FontAwesomeIcon icon={faClipboard} />
          <p>Centimeter</p>
        </NavLink>
        <NavLink to="/info">
          <FontAwesomeIcon icon={faMobileScreen} />
          <p>Application Info</p>
        </NavLink>
        <NavLink to="/member">
          <FontAwesomeIcon icon={faIdCardClip} />
          <p>Member</p>
        </NavLink>
        <NavLink to="/version-app">
          <FontAwesomeIcon icon={faMobileAlt} />
          <p>Version</p>
        </NavLink>

        <div className="side-buttom">
          <Button onClick={OnSignOutHandler} className="logout">
            <FontAwesomeIcon icon={faSignOut} />
            <p>&nbsp; &nbsp;Sign Out</p>
          </Button>
          <p className="v-mobile">Version {localStorage.getItem("versionApp")}</p>
        </div>
      </ul>
    </aside>
  );
};
export default SideBar;
