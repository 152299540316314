import axios from "axios";

export const getCmCate = (langId) => {
  return axios.get(`/cm/cate/all/${langId}`).then(
    (res) => {
      return { status: true, data: res.data.data };
    },
    (error) => {
      return {
        status: false,
        description: !error.response.data
          ? "Something went wrong."
          : error.response.data.description,
      };
    }
  );
};

export const getCmPostByCateID = (id) => {
  return axios.get(`/cm/post/${id}`).then(
    (res) => {
      return { status: true, data: res.data.data };
    },
    (error) => {
      return {
        status: false,
        description: !error.response.data
          ? "Something went wrong."
          : error.response.data.description,
      };
    }
  );
};

export const addCmCate = (formBody) => {
  return axios.post(`/cm/add/cate`, formBody).then(
    (res) => {
      return { status: true, data: res.data };
    },
    (error) => {
      return {
        status: false,
        description: !error.response.data
          ? "Something went wrong."
          : error.response.data.description,
      };
    }
  );
};

export const editCmCate = (formBody) => {
  return axios.post(`/cm/update/cate`, formBody).then(
    (res) => {
      return { status: true, data: res.data };
    },
    (error) => {
      return {
        status: false,
        description: !error.response.data
          ? "Something went wrong."
          : error.response.data.description,
      };
    }
  );
};

export const removeCmCate = (id) => {
  return axios.post(`/cm/remove/cate`, { id: id }).then(
    (res) => {
      return { status: true, data: res.data };
    },
    (error) => {
      return {
        status: false,
        description: !error.response.data
          ? "Something went wrong."
          : error.response.data.description,
      };
    }
  );
};

export const ManageCm = (jsonData) => {
  return axios.post(`/cm/update/post`, jsonData).then(
    (res) => {
      return { status: true, data: res.data.data };
    },
    (error) => {
      return {
        status: false,
        description: !error.response.data
          ? "Something went wrong."
          : error.response.data.description,
      };
    }
  );
};
