import { Suspense } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import "./App.scss";

import PrivateRoutes from "./utils/PrivateRoutes";
import LoginPage from "./pages/login/login";
// import DashboardPage from "./pages/dashboard/dashboard";
import InchPage from "./pages/inch/inch";
import Centimeter from "./pages/centimeter/centimeter";
import AppInfo from "./pages/app_info/app_info";
import Member from "./pages/member/member";
import VersionApp from "./pages/version/version";

function App() {
  return (
    <Suspense>
      <Routes>
        <Route element={<PrivateRoutes />}>
          {<Route path="/" element={<Navigate to="/inch" />} />}
          {/* {<Route path="dashboard" element={<DashboardPage />} />} */}
          {<Route path="info" element={<AppInfo />} />}
          {<Route path="inch" element={<InchPage />} />}
          {<Route path="centimeter" element={<Centimeter />} />}
          {<Route path="member" element={<Member />} />}
          {<Route path="version-app" element={<VersionApp />} />}
        </Route>
        {<Route path="login" element={<LoginPage />} />}
      </Routes>
    </Suspense>
  );
}

export default App;
