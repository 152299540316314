import { useEffect, useState } from "react";

import "./centimeter.scss";
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAdd, faBook, faEdit, faListAlt, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import AddModal from "./centimeter-modal/add-modal";
import SwalUI from "../../components/ui/swal-ui";
import EditModel from "./centimeter-modal/edit-modal";
import ManagePostCm from "./centimeter-modal/manage-post";
import { getCmCate, getCmPostByCateID, removeCmCate } from "../../services/centimeter.service";
import { useSelector } from "react-redux";
const CategoryPage = () => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [category, setCategory] = useState([]);
  const [cateModalAdd, setCateModalAdd] = useState(false);
  const [refreshData, setRefreshData] = useState(0);

  const [cateEditData, setCateEditData] = useState(null);
  const [openEditModal, setOpenEditModal] = useState(false);

  const [posts, setPosts] = useState([]);
  const [openManagePost, setOpenManagePost] = useState(false);
  const lang = useSelector((state) => state.app.langId);

  useEffect(() => {
    getCmCate(lang).then((res) => {
      let arrData = [];
      if (res.status) {
        for (const obj of res.data) {
          arrData[`${obj.id}`] = {
            id: obj.id,
            cate_name: obj.cate_name,
            cate_description: obj.cate_description,
            language_id: obj.language_id,
            position: obj.position,
            luck:obj.luck,
          };
        }
      }
      setCategory(arrData);
      console.log("then");
    });
  }, [refreshData]);

  const removeCate = (id) => {
    removeCmCate(id).then((res) => {
      if (res.status) {
        setRefreshData((prev) => prev + 1);
        SwalUI({ status: res.status, description: res.data.description });
      }
    });
  };

  const editCate = (item) => {
    setOpenEditModal(true);
    setCateEditData(item);
  };

  const openPost = (id) => {
    getCmPostByCateID(id).then((res) => {
      if (res.status) {
        setPosts(res.data);
      }
    });
    setOpenManagePost(true);
  };

  return (
    <div className="category-page">
      <div className="title">
        <p>Categorise Centimeter</p>
      </div>
      <div className="col-tabtop">
        <Button variant="outlined" onClick={() => setCateModalAdd(true)}>
          <FontAwesomeIcon icon={faAdd} />
          เพิ่ม
        </Button>
      </div>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <TableCell align="center">
                <FontAwesomeIcon icon={faBook} />
              </TableCell>
              <TableCell>Title</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Position</TableCell>
              <TableCell>Luck</TableCell>
              <TableCell align="center">แก้ไข</TableCell>
              <TableCell align="center">ลบ</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {category.map((el) => (
              <TableRow key={el.id}>
                <TableCell align="center">
                  <Button onClick={() => openPost(el.id)}>
                    <FontAwesomeIcon icon={faBook} />
                  </Button>
                </TableCell>
                <TableCell align="left">{el.cate_name}</TableCell>
                <TableCell align="left">{el.cate_description}</TableCell>
                <TableCell align="left">{el.position}</TableCell>
                <TableCell align="left" style={{ color: el.luck === 0 ? "red" : "black" }}>
                  {el.luck === 0 ? "โชคดี" : "โชคร้าย"}
                </TableCell>
                <TableCell align="center">
                  <Button onClick={() => editCate(el)}>
                    <FontAwesomeIcon icon={faEdit} />
                  </Button>
                </TableCell>
                <TableCell align="center">
                  <Button onClick={() => removeCate(el.id)}>
                    <FontAwesomeIcon icon={faTrashCan} style={{ color: "red" }} />
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <AddModal
        cateModalAdd={cateModalAdd}
        setCateModalAdd={setCateModalAdd}
        setRefreshData={() => setRefreshData(refreshData + 1)}
      />
      <EditModel
        openEditModal={openEditModal}
        setOpenEditModal={setOpenEditModal}
        cateEditData={cateEditData}
        setRefreshData={() => setRefreshData(refreshData + 1)}
      />
      <ManagePostCm
        openManagePost={openManagePost}
        setOpenManagePost={setOpenManagePost}
        setRefreshData={() => setRefreshData(refreshData + 1)}
        posts={posts}
      />
    </div>
  );
};
export default CategoryPage;
