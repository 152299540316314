import { Button, Card, CardContent, TextField, Typography } from "@mui/material";
import "./app_info.scss";
import { useEffect, useRef, useState } from "react";
import {
  getAppInfo,
  updateAboutApp,
  uploadBackgourndImage,
  uploadRulerImage,
} from "../../services/info.service";
import { useSelector } from "react-redux";
import appDev from "../../store/config";
import SwalUI from "../../components/ui/swal-ui";
const dataDefault = {
  id: "",
  about_app: "",
  background_image: "",
  ruler_image: "",
};
const AppInfo = () => {
  const lang = useSelector((state) => state.app.langId);

  const [info, setInfo] = useState(dataDefault);
  const [refreshData, setRefreshData] = useState(0);

  const [bgImage, setBgImage] = useState(null);
  const [rulerImage, setRulerImage] = useState(null);
  const [saveBgImage, setSaveBgImage] = useState(null);
  const [saveRulerImage, setSaveRulerImage] = useState(null);
  // const about = useRef();

  useEffect(() => {
    getAppInfo(lang).then((res) => {
      if (res.data[0]) {
        setInfo(res.data[0]);
        setBgImage(`${appDev.pathPublic}/${info.background_image}`);
        setRulerImage(`${appDev.pathPublic}/${info.ruler_image}`);
      }
    });
  }, [info.background_image, info.ruler_image, lang, refreshData]);

  const handleImageBgImage = (event) => {
    const selectedImage = event.target.files[0];
    setSaveBgImage(selectedImage);
    if (selectedImage) {
      const reader = new FileReader();
      reader.onload = () => {
        setBgImage(reader.result);
      };
      reader.readAsDataURL(selectedImage);
    }
  };
  const handleImageRulerImage = (event) => {
    const selectedImage = event.target.files[0];
    setSaveRulerImage(selectedImage);
    if (selectedImage) {
      const reader = new FileReader();
      reader.onload = () => {
        setRulerImage(reader.result);
      };
      reader.readAsDataURL(selectedImage);
    }
  };

  const saveAbout = (event) => {
    const formData = new FormData();
    formData.append("about_app", info.about_app);
    formData.append("id", info.id);
    updateAboutApp(formData).then((res) => {
      if (res.status) {
        SwalUI({ status: res.status, description: res.data.description });
        setRefreshData((prev) => prev + 1);
      } else {
        SwalUI({ status: res.status, description: res.data.description });
      }
    });
  };

  const saveImageBgImage = (event) => {
    const formData = new FormData();
    formData.append("bgimage", saveBgImage);
    formData.append("id", info.id);

    uploadBackgourndImage(formData).then((res) => {
      if (res.status) {
        SwalUI({ status: res.status, description: res.data.description });
        setRefreshData((prev) => prev + 1);
      } else {
        SwalUI({ status: res.status, description: res.data.description });
      }
    });
  };
  const saveRulerBgImage = (event) => {
    const formData = new FormData();
    formData.append("rulerimage", saveRulerImage);
    formData.append("id", info.id);

    uploadRulerImage(formData).then((res) => {
      if (res.status) {
        SwalUI({ status: res.status, description: res.data.description });
        setRefreshData((prev) => prev + 1);
      } else {
        SwalUI({ status: res.status, description: res.data.description });
      }
    });
  };

  return (
    <div className="app-info-page">
      <div className="title">
        <p>Application Info</p>
      </div>
      <div className="content">
        <Card className="custom-card">
          <CardContent>
            <Typography variant="h6" component="div">
              Appliction Info
            </Typography>
            <TextField
              size="small"
              variant="outlined"
              multiline
              sx={{ width: "420px" }}
              rows={6}
              // ref={about}
              value={info.about_app ? info.about_app : ""}
              onChange={(e) => {
                setInfo((prevState) => {
                  return { ...prevState, about_app: e.target.value };
                });
              }}
            />
            <div className="btn-about">
              <Button variant="contained" onClick={saveAbout}>
                SAVE
              </Button>
            </div>
          </CardContent>
        </Card>
        <Card className="custom-card">
          <CardContent>
            <Typography variant="h6" component="div">
              Background Image
            </Typography>
            <div className="preview">
              <img src={bgImage === "" ? "/images/noimage.png" : bgImage} alt="preview" />
            </div>
            <div className="btn">
              <input
                className="custom-upload"
                type="file"
                onChange={handleImageBgImage}
                accept="image/*"
              />
              <Button variant="contained" onClick={saveImageBgImage}>
                SAVE
              </Button>
            </div>
          </CardContent>
        </Card>
        <Card className="custom-card">
          <CardContent>
            <Typography variant="h6" component="div">
              Ruler Image
            </Typography>
            <div className="preview">
              <img src={rulerImage === "" ? "/images/noimage.png" : rulerImage} alt="preview" />
            </div>
            <div className="btn">
              <input
                className="custom-upload"
                type="file"
                onChange={handleImageRulerImage}
                accept="image/*"
              />
              <Button variant="contained" onClick={saveRulerBgImage}>
                SAVE
              </Button>
            </div>
          </CardContent>
        </Card>
      </div>
    </div>
  );
};
export default AppInfo;
