import "./login.scss";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, Navigate } from "react-router-dom";
import axios from "axios";
import { authActions } from "../../store/app-auth";

const LoginPage = () => {
  const dispatch = useDispatch();
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const usernameRef = useRef();
  const passwordRef = useRef();
  const keepRef = useRef();
  const [validate, setValidate] = useState();

  useEffect(() => {}, [isLoggedIn]);

  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }

  const signInHandler = async () => {
    // console.log(usernameRef.current.value);
    if (!isValidEmail(usernameRef.current.value)) {
      usernameRef.current.focus();
      console.log("email is invalid")
      setValidate("email is invalid");
      return false;
    }

    if (passwordRef.current.value.trim().length < 5) {
      passwordRef.current.focus();
      setValidate("password is invalid")
      return false;
    }

    try {
      axios
        .post("/login", {
          email: usernameRef.current.value.trim(),
          password: passwordRef.current.value.trim(),
        })
        .then(
          (response) => {
            dispatch(
              authActions.login({
                token: response.data.token,
                // keepLogin: keepRef.current.checked,
              })
            );
            setValidate(null)
            console.log("success");
          },
          (error) => {
            setValidate(true);
            if (error.response.status === 422) {
              const errorDesc = error.response.data.description.reduce(
                (previous, current) => (previous += `<p>login</p>`),
                ""
              );
            } else {
              console.log(error)
              setValidate("invalid data");
            }
          }
        );
        console.log(validate)
    } catch (err) {}
  };
  if (isLoggedIn) {
    return <Navigate to="/" />;
  }
  return (
    <section id="login-page">
      <div id="form-blog">
        <div className="title">
          <h1>Sign In</h1>
          <p>Sign to stay connected.</p>
        </div>
        <div className="form">
          <div className="group">
            {/* <label>Email</label> */}
            <input type="text" ref={usernameRef} />
          </div>
          <div className="group">
            {/* <label>Password</label> */}
            <input type="password" ref={passwordRef} />
          </div>
          <p style={{ color: "red",margin:"4px" }}>{validate}</p>
          <div className="group-check-forgot">
            {/* <div className="check-box">
              <input type="checkbox" />
              <p>Remember me</p>
            </div> */}
            {/* <div className="forgot">
              <a href="#forgot">Forgot password</a>
            </div> */}
          </div>
          <div className="btn">
            <button onClick={signInHandler}>SIGN IN</button>
          </div>
          {/* <div className="col-sign-up">
            <p>
              Don’t have an account?
              <Link to="/#"> Click here to sign up.</Link>
            </p>
          </div> */}
        </div>
      </div>
      <div id="image-blog"></div>
    </section>
  );
};
export default LoginPage;
