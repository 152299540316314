import { createSlice } from "@reduxjs/toolkit";

const initialAuthState = {
  isLoggedIn: !!localStorage.authToken,
  authToken: localStorage.authToken,
  adminId: null,
  userRoleName: null,
  userRoleId: null,
  keepLogin: !!localStorage.keepLogin ? localStorage.keepLogin : false,
  activateLanguage: [],
  tokenCount: 0,
};

const authSlice = createSlice({
  name: "auth",
  initialState: initialAuthState,
  reducers: {
    login(state, action) {
      state.adminId = null;
      localStorage.removeItem("authToken");
      localStorage.setItem("authToken", action.payload.token);
      state.authToken = action.payload.token;
      state.keepLogin = action.payload.keepLogin;
      state.isLoggedIn = true;
    },
    logout(state) {
      state.tokenCount = 0;
      state.keepLogin = false;
      state.authToken = null;
      state.authToken = null;
      state.isLoggedIn = false;
      localStorage.removeItem("authToken");
      localStorage.removeItem("rft");
    },
  },
});

export const authActions = authSlice.actions;
export default authSlice;
