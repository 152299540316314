import { Button, Modal, Box, TextField, Radio } from "@mui/material";
import { useState } from "react";
import { addInchCate } from "../../../services/inch.service";
import SwalUI from "../../../components/ui/swal-ui";
import { useSelector } from "react-redux";
const dataDefault = {
  cate_name: "",
  cate_description: "",
  position: "",
  language_id: 0,
  luck:"true"
};
const AddModal = (props) => {
  const { cateModalAdd, setCateModalAdd } = props;
  const [addData, setAddData] = useState(dataDefault);
  const lang = useSelector((state) => state.app.langId);


  const onSaveCate = () => {
    const formData = new FormData();
    formData.append("cate_name", addData.cate_name);
    formData.append("cate_description", addData.cate_description);
    formData.append("position", addData.position);
    formData.append("language_id", lang);
    formData.append("luck",(addData.luck==="true")?0:1);
    addInchCate(formData).then((res) => {
      if (res.status) {
        console.log(res.data.description);
        props.setRefreshData((prev) => prev + 1);
        props.setCateModalAdd(false);
        setAddData(dataDefault);
        SwalUI({ status: res.status, description: res.data.description });
      } else {
        SwalUI({ status: res.status, description: res.description });
      }
    });
  };

  return (
    <Modal
      className="custom-modal"
      open={cateModalAdd}
      onClose={() => props.setCateModalAdd(false)}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <Box className="body-modal">
        <div className="modal-top">
          <h1>เพิ่ม</h1>
        </div>
        <TextField
          className="custom-input"
          id="cate-name"
          size="small"
          variant="outlined"
          label="Title"
          value={addData.cate_name}
          onChange={(e) => {
            setAddData((prevState) => {
              return { ...prevState, cate_name: e.target.value };
            });
          }}
        />
        <TextField
          className="custom-input"
          id="cate-description"
          size="small"
          variant="outlined"
          label="Description"
          value={addData.description}
          multiline
          rows={3}
          onChange={(e) => {
            setAddData((prevState) => {
              return { ...prevState, cate_description: e.target.value };
            });
          }}
        />
        <TextField
          className="custom-input"
          id="cate-description"
          size="small"
          variant="outlined"
          label="Position"
          value={addData.position}
          onChange={(e) => {
            setAddData((prevState) => {
              return { ...prevState, position: e.target.value };
            });
          }}
        />
        <div className="radio-luck">
          <Radio
            checked={addData.luck === "true"}
            onChange={(e) => {
              setAddData((prevState) => {
                return { ...prevState, luck: e.target.value };
              });
            }}
            value="true"
            name="radio-buttons"
            style={{ color: "red" }}
          />
          <p style={{ color: "red" }}>โชคดี</p>
          <Radio
            checked={addData.luck === "false"}
            onChange={(e) => {
              setAddData((prevState) => {
                return { ...prevState, luck: e.target.value };
              });
            }}
            value="false"
            name="radio-buttons"
            style={{ color: "black" }}
          />
          <p>โชคร้าย</p>
        </div>

        <div className="modal-footer">
          <Button className="btn-cancel" onClick={() => props.setCateModalAdd(false)}>
            CANCEL
          </Button>
          <Button className="btn-save" onClick={onSaveCate}>
            SAVE
          </Button>
        </div>
      </Box>
    </Modal>
  );
};
export default AddModal;
